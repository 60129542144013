.header {
  padding-bottom: 18px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 49;
  }

  .menu-active & {
    @media screen and (max-width: 767px) {
      z-index: 100;
    }
  }
}

.sub-screen {
  display: none;
}

.page-active .sub-screen {
  display: block;
}

.menu-bar {
  margin: -5px 6px 0 0;
}


body {
  min-width: 375px;
  color: #fff;
}

.react-select-wrap {

  .react-select__control {
    border: none;
    background: rgba(255, 255, 255, 0.2);
    position: relative;
    border-radius: 8px;
    outline: none;
    height: 56px;
    box-shadow: none;

    @media (max-width: 640px) {
      margin-bottom: 10px;
      height: 30px;
    }

    .react-select__value-container {
      padding: 10px 15px;
      outline: none;

      @media(max-width: 640px) {
        padding: 0 15px;
      }

    }
  }



  .react-select__control .react-select__value-container .react-select__single-value {
    color: #fff;
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    color: #fff;
    opacity: 0.3;
  }

  .react-select__dropdown-indicator {
    position: relative;

    svg {
      display: none;
    }

    &:before {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 18px;
      top: 1px;
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
      ;
      content: '';
      transform: rotate(-135deg);
    }
  }
}

.react-tabs__tab--selected a {
  background: linear-gradient(63.82deg, #733381 23.39%, #FB9A27 101.67%);
}

.react-select__menu-list {
  background: linear-gradient(63.82deg, #B374C1 23.39%, #F59B2F 101.67%);
}

.react-select__menu {
  color: #fff;
}

.react-select__menu .react-select__option {
  color: #fff;
}

.react-select__menu .react-select__option.react-select__option--is-focused,
.react-select__menu .react-select__option.react-select__option--is-selected {
  background: #F37474;
}

.fake-check {
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.08);
  height: 17px;

  input[type="checkbox"]:checked {
    ~.fake-check {
      background: #733381;
      backdrop-filter: blur(10px);
    }
  }
}

.form {

  @media screen and (max-width: 767px) {
    background: #FFFFFF1A;
    backdrop-filter: blur(50px);
  }

  @media screen and (min-width: 767px) {
    position: relative;

    &:before {
      background: #FFFFFF1A;
      backdrop-filter: blur(50px);
      content: '';
      position: absolute;
      border-radius: 0 16px 16px 0px;
      left: -9999px;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.inputsearsh_bar {
  position: relative;

  &::before {
    content: '';
    border-left: 1px solid #c13041;
    border-right: 1px solid #bd9600;
    background-image: linear-gradient(90deg, #733381, #FB9A27), linear-gradient(90deg, #733381, #FB9A27);
    background-size: 100% 1px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.check {
  border: none;
  position: relative;
  background: transparent;
}

.box {
  border: none;
  background: transparent;
}

.check::after {
  width: 5px;
  height: 10px;
  position: absolute;
  right: 6px;
  top: 2px;
  border-top: 2px solid #FFF;
  border-left: 2px solid #FFF;
  content: '';
  transform: rotate(-135deg);
}

.download-btn {

  &:hover {

    svg {

      path {
        fill: #000
      }
    }
  }
}

.select-parent {

  .react-select-wrap {

    .react-select__control {
      min-height: 48px;
    }
  }
}

.rank-field {

  input[type='text'] {
    padding-left: 16px !important;
  }
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
    display: block;
  }

  @media screen and (max-width: 767px) {
    position: relative;
    overflow: hidden;
  }

  &:before {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 90;
    transition: all 0.4s ease-in-out;

    .filter-bar-active &,
    .menu-active & {
      opacity: 1;
      visibility: visible;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.filter-bar-active,
.menu-active {
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
}

.sidebar-filter {

  @media screen and (max-width: 767px) {
    transition: all 0.4s ease-in-out;
    right: -300px;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;

    .filter-bar-active & {
      right: 0;
    }
  }
}

.nav-drop {

  @media screen and (max-width: 767px) {
    transition: all 0.4s ease-in-out;
    right: -300px;
    top: 0;
    height: 100vh;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(180deg, #1A1123 0%, #320412 100%), #000000;
    z-index: 100;

    .menu-active & {
      right: 0;
    }
  }
}

.nav-wrap {

  @media screen and (max-width: 767px) {
    min-height: 100vh;
  }
}

.slide {
  overflow: hidden;
  padding: 5px;
  position: relative;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Place pseudo-element behind the container */
    background: linear-gradient(to bottom, #733381, #FB9A27) no-repeat;
    background-size: 100% 100%;
  }
}

.bg-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: radial-gradient(70.41% 105.87% at 54.41% 88.06%, #A33506 0%, #69144C 64.86%, #24002C 100%);

  &:before {
    position: absolute;
    inset: 5px;
    content: '';
    background: #000;
    opacity: 0.6;
    border-radius: 20px;
    transition: all 0.4s ease;
  }
}

.slick-slide {
  transform: scale(0.7);
  transform-origin: 50% 50%;

  .slide {
    img {
      width: 80px;
      height: auto;

      @media (max-width: 767px) {
        width: 70px;
      }
    }
  }

  .bg-slide  {
    height: 150px;

    @media (max-width: 767px) {
      height: 110px;
    }
  }

  &.slick-center {
    transform: none;

    .slide {
      img {
        width: 110px;
        height: auto;

        @media (max-width: 767px) {
          width: 80px;
        }
      }
    }

    .bg-slide {
      background: radial-gradient(70.41% 105.87% at 54.41% 88.06%, #A33506 0%, #69144C 64.86%, #24002C 100%);

      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }

    .hide-text {
      opacity: 1;
      visibility: visible;
    }
  }
}

.hide-text {
  opacity: 0;
  visibility: hidden;
}

.btn {
  max-width: 156px;
  width: 100%;
  margin: 0 auto;
}

.result {
  .image-radius {
    border-radius: 20px;
    background-image: linear-gradient(to bottom right, #FB9A27, #733381);
    padding: 2px;
  }
}

.result-circle {
  .result-circle {
    position: relative;

    &:before {
      position: absolute;
      top: 7px;
      left: 10px;
      bottom: 0;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #fff;
      content: "";
    }
  }
}

.element {
  .bgElement {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 20px 10px;

    &:active {
      background-color: linear-gradient(63.82deg, rgba(115, 51, 129, 0.8) 23.39%, rgba(251, 154, 39, 0.8) 101.67%);
    }
  }
}

.two-col {
  padding-top: 57px;
}

.text {
  line-height: 46.8px;
  letter-spacing: -1px;
}

.paragraph {
  line-height: 1.5;
}

.image {
  clip-path: polygon(9% 0, 80% 0%, 100% 0, 100% 90%, 92% 100%, 0 100%, 0% 80%, 0 10%);
}

.right-col {
  padding-left: 74px;
}

.para {
  line-height: 1.7;
  letter-spacing: 0.4px;
}

.table {
  width: 100%;
  font-family: 'K2D';

  .row {
    display: flex;

    .first {
      border: 1px solid rgba(255, 255, 255, 0.3);
      width: 32.2%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      padding: 6px;
      font-size: 16px;
      background: rgba(255, 255, 255, 0.12);

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }


    .fill {
      width: 19.5%;
      border: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      padding: 4px;
      background: rgba(255, 255, 255, 0.12);

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  .num {
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 58.5%;
    background: rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    padding: 6px;
    line-height: 1;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.tableElement {
  width: 100%;
  font-family: 'K2D';

  .firstRow {
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;

    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }

  .rowArea {
    display: flex;
    text-align: center;

    .fillfirst {
      width: 21%;
      border: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;

      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
  }

  .selfRow {
    display: flex;
    text-align: center;
    background: rgba(255, 255, 255, 0.12);

    .fillfirst {
      border: 1px solid rgba(255, 255, 255, 0.3);
      width: 16%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;

      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }

    .fillfirst {
      width: 21%;
      border: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;

      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
  }

  .num {
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  .elemntRow {
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px;
    text-align: center;
    background: rgba(255, 255, 255, 0.12);
  }
}

.activeButton {
  background: linear-gradient(63.82deg, #733381 23.39%, #FB9A27 101.67%);
  border-radius: 8px;
}

.circle {
  animation: rotate 3s linear infinite;
}

.content-center {
  animation: rotate 4s linear infinite;
  top: 18%;
  left: 24%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.circle-block {
  .title-area {
    position: absolute;
    top: 86px;
    left: 105px;
  }

  .title-text {
    position: absolute;
    top: 96px;
    left: 100px;
  }
}

/* ----  zubair dev ---- */

.banner {
  position: relative;
  isolation: isolate;
  clip-path: polygon(3% 0, 100% 0, 100% 20%, 100% 92%, 97% 100%, 28% 100%, 0 100%, 0 11%);
}

.banner::before,
.banner::after {
  content: '';
  position: absolute;
  inset: 0;
}

.banner::before,
.banner::after {
  background-image: radial-gradient(142.64% 374.81% at 103.26% 120.14%, #571A00 0%, #320030 34.58%, #000000 100%);
  ;
  z-index: -2;
}

/* ----  zubair dev ---- */

.error-msg {
  padding-top: 4px;

  @media screen and (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 10px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  user-select: none;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.welcome-screen {
  transition: all 0.4s ease;

  .page-active & {
    transform: translate(0, -100%);
  }
}

.searchbar {
  background-color: #110010;
  cursor: pointer;
}

.dropdown-opener {
  position: relative;

  &:before {
    position: fixed;
    inset: 0;
    background: transparent;
    content: '';
    z-index: 2;
    display: none;

    .dropdown-open & {
      display: block;
    }
  }
}

.border-search {
  position: relative;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    border-left: 3px solid rgba($color: #733381, $alpha: .8);
    border-right: 2px solid rgba($color: #FB9A27, $alpha: .8);
    background-image: linear-gradient(90deg, #733381, #FB9A27), linear-gradient(90deg, #733381, #FB9A27);
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    z-index: 2;
    pointer-events: none;
  }
}

.filter-bar {
  position: relative;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    border-left: 2px solid rgba($color: #733381, $alpha: .8);
    border-right: 2px solid rgba($color: #FB9A27, $alpha: .8);
    background-image: linear-gradient(90deg, #733381, #FB9A27), linear-gradient(90deg, #733381, #FB9A27);
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    pointer-events: none;
  }
}

.filters-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 15px;
}

.cards-holder {
  min-height: 300px;
}

.box-model {
  position: absolute;
  top: 68px;
  left: -60px;
  z-index: 2;
  width: 290px;

  @media (max-width: 767px) {
    left: 0;
  }

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    border-left: 2px solid rgba($color: #733381, $alpha: .8);
    border-right: 2px solid rgba($color: #FB9A27, $alpha: .8);
    background-image: linear-gradient(90deg, #733381, #FB9A27), linear-gradient(90deg, #733381, #FB9A27);
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    pointer-events: none;
  }
  
  .box-wrap {
    padding: 24px 30px;
    max-height: 400px;
    overflow: auto;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: gray;
    margin: 0 0 5px;
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 800;
    font-family: K2D;
    margin: 0 0 10px;
  }

  .Accordion {
    display: block;
  }

  .accordion-header {
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    font-family: "poppins";
    margin: 0 0 10px;
    position: relative;
    padding-right: 2px;
    cursor: pointer;

    .accordion-icon {
      cursor: pointer;
      position: absolute;
      top: -3px;
      right: 0;
      background-image: linear-gradient(63.82deg, rgba(115, 51, 129, 0.8) 23.39%, rgba(251, 154, 39, 0.8) 101.67%);
      width: 20px;
      height: 20px;
      border-radius: 100%;
      transition: all 0.4s ease;

      &.open {
        
        &:after {
          transform: translate(0, -50%);
        }
      }

      &::before,
      &:after {
        width: 10px;
        height: 2px;
        background: #fff;
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 5px;
        transition: all 0.4s ease;;
      }

      &:after {
        transform: rotate(90deg);
        margin: -1px 0 0;
      }

      .open {
         &:after {
            transform: translate(-50%, -50%) rotate(180deg);
          }
      }
    }
  }



  .accordion-list {
    font-size: 12px;
    margin: 0 0 10px;
    font-weight: 400;

    li {
      padding-bottom: 5px;
    }
  }
}

.tab_btn {
  position: relative;
  padding: 10px  12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    inset: 0;
    content: '';
    border-left: 2px solid rgba($color: #733381, $alpha: .8);
    border-right: 2px solid rgba($color: #FB9A27, $alpha: .8);
    background-image: linear-gradient(90deg, #733381, #FB9A27), linear-gradient(90deg, #733381, #FB9A27);
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    pointer-events: none;
  }

  svg {
    margin-left: 14px;
  }
}

.audio-player {
  position: absolute;
  right: 0;
  top: 100%;
  margin: 15px 0 0;
  z-index: 5;

  .track-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 40px 0 0;
    align-items: center;

    @media (max-width: 767px) {
      padding: 10px 20px 0 0;
    }

    svg {
      margin: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 15px;
        height: auto;
        margin: 0 5px 0 0;
      }
    }
  }
}

.bg-btnBgClr  {
  &:disabled {
    background: #292929 !important;
    color: #fff !important;
    text-align: center !important;
    display: block;

    span {
      display: none;
    }
  }
}

/* range slider styles */

.range-slider {
  position: relative;
  width: 140px;

  @media (max-width: 767px) {
    width: 100px;;
  }

  .filled-audio {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: 10px;
    background: linear-gradient(63.82deg, rgba(115, 51, 129, 0.8) 23.39%, rgba(251, 154, 39, 0.8) 101.67%);
    z-index: 2;
    pointer-events: none;

    @media (max-width: 767px) {
      height: 6px;
      margin: 1px 0 0;
    }

    .circle-item {
      position: absolute;
      right: -9px;
      top: -2px;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #D9D9D9;
      content: '';
    }
  }

  input[type=range] {
    -webkit-appearance: none; /* Hides the slider in Safari and Chrome */
    width: 100%;
    height: 10px;
    background-color: #D9D9D9;
    border-radius: 5px;
    outline: none;

    @media (max-width: 767px) {
      height: 6px;
    }
  }
  
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Hides the slider thumb in Safari and Chrome */
    width: 22px;
    height: 22px;
    background: url("../../public/assets/images/circle.svg");
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 3;
  }
  
  input[type=range]::-moz-range-thumb {
    width: 22px;
    height: 22px;
    background: url("../../public/assets/images/circle.svg");
    background-size: 100% 100%;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 3;
  }
}


button[type='button'] {
  justify-content: center;
  align-items: center;
}
